body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  color: #ffffff;
}

body {
  background: url('back.png') no-repeat center center fixed; 
  background-size: cover;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

header, section {
  background-color: rgba(0, 0, 0, 0.8);
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  font-size: 1.2rem;
}

.social-links a {
  margin: 0 10px;
  text-decoration: none;
  color: #ffffff;
}

#contact {
  color: #a5a5a5; /* Adjust the color code as needed */
}

.email{
  color: #ffffff;
}

#projects ul {
  padding: 0;
}

#projects ul li {
  margin-bottom: 20px; /* Adjust this value to control the space between list items */
  margin-left: 40px;
}

/* Reduce space between the p tag and other elements in the same li */
#projects ul li p {
  font-size: 1rem;
  color: #aaaaaa;
  margin-top: 5px; /* Adjust this value to control the space between the p tag and other elements */
  margin-bottom: 10px; /* Adjust this value to control the space below the p tag */
}

ul {
  margin-top: 10px;
}

li {
  margin-bottom: 10px;
}